<template>
    <div>
        <Navbar :page="`Diagnóstico ${(diagnostico && diagnostico.diag_base ? diagnostico.diag_base.tema : '') + ' - ' + (diagnostico && diagnostico.destinatarioAgente ? diagnostico.destinatarioAgente.nome : (diagnostico && diagnostico.destinatarioPessoa ? diagnostico.destinatarioPessoa.nome : ''))}`" />
        <div class="mx-4 my-4 md:mx-8 md:my-8" v-if="diagnostico">
            Status: <b>{{diagnostico.status}}</b>
            
            <div class="bg-gray-200 py-2 px-4 rounded">
                <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-6">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">Questão</label>
                    </div>
                    <div class="col-span-4">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">Resposta</label>
                    </div>
                    <div class="col-span-2">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">Resposta</label>
                    </div>
                </div>
            </div>
            <div v-for="(item, idx) in perguntas" :key="item._id" class="border-b-2 border-gray-200 py-2 px-4 mb-2">
                <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-12 md:col-span-6">
                        <label class="text-left text-sm font-medium text-black"><b>{{ idx + 1 }} - </b>{{ item.pergunta }}</label>
                    </div>
                    <div class="col-span-12 md:col-span-4">
                        <div class="mt-2 w-full">
                            <label :for="`pergunta-${idx}-Sim`" class="inline-flex items-center">
                                <input :class="{'opacity-50': diagnostico.status === 'Finalizado'}" :disabled="diagnostico.status === 'Finalizado'" type="radio" class="form-radio" :for="`pergunta-${idx}-Sim`" :name="`pergunta-${idx}`" v-model="item.resposta" value="Sim">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Sim</span>
                            </label>
                            <label :for="`pergunta-${idx}-Nao`" class="inline-flex items-center ml-1 md:ml-2">
                                <input :class="{'opacity-50': diagnostico.status === 'Finalizado'}" :disabled="diagnostico.status === 'Finalizado'" type="radio" class="form-radio" :for="`pergunta-${idx}-Nao`" :name="`pergunta-${idx}`" v-model="item.resposta" value="Não">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Não</span>
                            </label>
                            <label :for="`pergunta-${idx}-Naoaplica`" class="inline-flex items-center ml-1 md:ml-2">
                                <input :class="{'opacity-50': diagnostico.status === 'Finalizado'}" :disabled="diagnostico.status === 'Finalizado'" type="radio" class="form-radio" :for="`pergunta-${idx}-Naoaplica`" :name="`pergunta-${idx}`" v-model="item.resposta" value="Não se aplica">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Não se aplica</span>
                            </label>
                            <label :for="`pergunta-${idx}-Outro`" class="inline-flex items-center ml-1 md:ml-2">
                                <input :class="{'opacity-50': diagnostico.status === 'Finalizado'}" :disabled="diagnostico.status === 'Finalizado'" type="checkbox" class="form-radio" :for="`pergunta-${idx}-Outro`" :name="`pergunta-${idx}`" v-model="item.comobs" value="Outro">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Observações</span>
                            </label>
                            <textarea :class="{'opacity-50': diagnostico.status === 'Finalizado'}" :disabled="diagnostico.status === 'Finalizado'" rows="3" type="text" v-if="item.comobs" v-model="item.outroResposta" placeholder="Resposta" :name="`pergunta-${idx}-Outro-Resposta`" class="block mt-2 focus:ring-yellow-400 focus:border-yellow-400 w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-2">
                        <label class="text-left text-sm font-bold tracking-wider" :class="{'text-green-500': item.resposta === 'Sim'}">{{ item.resposta }}</label>
                        <button :class="{'opacity-50': diagnostico.status === 'Finalizado'}" :disabled="diagnostico.status === 'Finalizado'" type="button" @click="addEvidencia(idx)" class="block mt-2 justify-center py-1 px-2 border border-transparent shadow-sm text-xs_3 font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                            Adicionar evidência
                        </button>
                    </div>
                    <div class="col-span-12 md:col-span-12">
                        <table v-if="item.evidencias && item.evidencias.length" class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <th scope="col" class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">Evidências</th>
                                <th scope="col" class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">Opções</th>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(doc, index) in item.evidencias" :key="doc.filename">
                                <td class="px-2 py-2 text-sm whitespace-nowrap">
                                    {{doc.originalname}}
                                </td>
                                <td class="px-2 py-2 whitespace-nowrap">
                                    <a 
                                    type="button"
                                    target="_blank"
                                    :href="`${url_api}/upload?mimetype=${doc.mimetype}&filename=${doc.filename}&folder=empresas`"
                                    class="mr-1 inline-flex justify-center py-1 px-3 border border-transparent shadow-sm text-xs_3 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">
                                    Visualizar
                                    </a>
                                    <button @click="excluirEvidencia(idx, index)" class="mr-1 inline-flex justify-center py-1 px-3 border border-transparent shadow-sm text-xs_3 font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400" type="button">Excluir</button>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-12">
                <div class="col-span-6">
                    <button @click="$router.back()" v-if="$store.state.user._id" type="button" class="inline-flex justify-center mr-2 py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                    <button 
                        v-if="diagnostico && diagnostico.status !== 'Finalizado'" 
                        @click="salvar()" 
                        type="button" 
                        class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400"
                    >
                        Salvar até aqui
                    </button>
                </div>
                <div class="col-span-6 text-right">
                    <button
                    v-if="diagnostico && diagnostico.status !== 'Finalizado'" 
                    @click="finalizar()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Finalizar questionário
                    </button>
                </div>
            </div>

        <modal name="sucessoFinalizar" :adaptive="true" :height="'auto'">
            <div id="idmodal" class="px-3 py-4 overflow-auto h-full md:h-auto">
                <h1 class="font-r font-semibold text-2xl mb-4">Questionário finalizado com sucesso!</h1>
                <button @click="$modal.hide('sucessoFinalizar')" class="text-white hover:bg-gray-600 bg-green-500 rounded py-2 px-12">
                    OK
                </button>
            </div>
        </modal>
        <modal name="addEvidencia" :width="800" :height="'auto'" :adaptive="true" :reset="true">
            <div class="px-3 py-2 overflow-y-auto h-full">
                <h2 class="text-base font-semibold mb-2"> 
                    Adicionar documento 
                </h2>
                <vue-dropzone 
                    ref="doc" id="doc" 
                    class="w-full" 
                    :options="dropzoneOptions" 
                    v-on:vdropzone-success="update">
                </vue-dropzone>
            </div>
        </modal>

        </div>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
    components: {
      vueDropzone,
    },
    data() {
        return {
            route: '/diagnosticos',
            diagnostico: null,
            perguntas: [],
            itemAddEvidencia: null,
            dropzoneOptions: this.$http.getDropzoneConfig(
            `upload`,
            'post',
            {
              maxFiles: 1,
              addRemoveLinks: true,
              capture: true,
              dictDefaultMessage: 'Clique ou arraste o arquivo aqui',
              dictRemoveFile: 'Remover'
            }
            ),
        }
    },
    methods: {
        async start(){
            const id = this.$route.params.id;
            
            if (id) {
                await this.$http.get(`/diagnosticoAplicado/${id}`)
                .then(resp=>{
                    this.diagnostico = resp.data;
                    this.perguntas = resp.data.perguntas;
                })
                .catch(()=> {
                    this.$vToastify.error("Perguntas não encontradas.");
                    this.$router.back();
                })
            }
        },
        async salvar(status) {
            if (status) this.diagnostico.status = status
            this.diagnostico.perguntas = [...this.perguntas]
            await this.$http.put(`/diagnosticoAplicado/`,  this.diagnostico)
            .then(()=>this.$vToastify.success(status ? 'Diagnóstico finalizado':'Diagnóstico salvo'))
            .catch(()=>this.$vToastify.error('Erro ao salvar diagnóstico'))
        },
        async finalizar() {
            this.$confirm({
                title: 'Finalizar questionário',
                message: `Deseja marcar o questionário como finalizado ?`,
                button: {
                    no: 'Não',
                    yes: 'Sim',
                },
                callback: async confirm => {
                    if (confirm) {
                        await this.salvar('Finalizado');
                    }    
                }
            });
        },
        async update(file, response) {
            if(response.file) this.perguntas[this.idxAddEvidencia].evidencias.push(response.file);
            this.$refs.doc.removeFile(file);
            this.itemAddEvidencia = null;
            this.$modal.hide('addEvidencia');
        },
        excluirEvidencia(idxPergunta, index) {
            if(this.perguntas[idxPergunta] && this.perguntas[idxPergunta].evidencias) this.perguntas[idxPergunta].evidencias.splice(index, 1);
        },
        addEvidencia(idx){
            this.idxAddEvidencia = idx;
            this.$modal.show('addEvidencia');
        }
    },
    async beforeMount() {
        this.start();
    },
}
</script>